import Vue from 'vue'
import { initConfig, envs } from "@/config"
import { initEnv } from 'plugs/env'
import { breakPoints } from "plugs/breakPoints"
import { initFavicon } from "plugs/favicon"
import '@/assets/css/global.scss'

/**
 * Устанавливаем "обязательные" плагины
 */
Vue.use(initConfig)
Vue.use(initEnv, { envs })
Vue.use(breakPoints)
Vue.use(initFavicon)

/**
 * Базовый класс для запуска приложения
 */
export class BaseApp {
  constructor () {
    /**
     * Начинаем слушать событие и запускаем приложение
     */
    document.addEventListener(
      "DOMContentLoaded",
      () => this.mount(),
      false
    )
  }

  /**
   * Селектор для монтирования приложения
   */
  get entrySelector () {
    return "#app"
  }

  /**
   * Заглушка для шаблона приложения
   */
  get template () {
    return `<div />`
  }

  /**
   * Заглушка для компонентов приложения
   */
  get components () {
    return {}
  }

  /**
   * Создаем приложение
   */
  build () {

    const {
      template,
      components
    } = this

    return new Vue({ template, components })
  }

  /**
   * Монтируем приложение
   */
  mount () {
    return this.build().$mount(this.entrySelector)
  }
}