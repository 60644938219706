/**
 * Выводит предупреждения в зависимости от текущего окружения.
 * Для production окружение, отправит в Сентри,
 * а для development/local выведет предупрежение с заданным префиксом.
 * 
 * @param {string} prefix Префикс с которым будет выводиться предупрежедение
 * 
 * Первый вызов функции задает префикс для последующих console.warn выводов.
 */
import * as Sentry from '@sentry/browser';
import { isString } from "@beast/utils"

const logHandler = (sentryFn, consoleFn) => prefix => {
  /**
   * Облагородим префикс :)
   */
  prefix = `[${ prefix }]`

  /**
   * Для продакш будет высылать все в Sentry
   */
  if (process.env.NODE_ENV === "production") {
    return (...args) => {
      const arg = [ prefix, ...args ].filter(el => isString(el)).join(" ")
      sentryFn(arg)
    }
  }

  /**
   * Для остального выведем в консоль
   */
  return (...args) => (
    typeof console !== 'undefined'
    &&
    consoleFn(prefix, ...args)
  )
}

export const warn = logHandler(
  arg => {
    Sentry.withScope(function(scope) {
      scope.setLevel("warning");
      scope.setTag("logger", "warning");
      Sentry.captureException(arg);
    });
  },
  console.warn
)

export const info = logHandler(
  arg => {
    Sentry.withScope(function(scope) {
      scope.setLevel("info");
      scope.setTag("logger", "info");
      Sentry.captureMessage(arg);
    });
  },
  console.info
)