import { Point } from "./point"

/**
 * @typedef {object} BreakPointsObject
 * @typedef {object} VueFunctionalComponent
 * 
 * @author Lobkov Alexandr <aslobko1@mts.ru>
 */

export const Width = {
  name: "breakpoint-hider-width",
  extends: Point,
  functional: true,
  props: {
    /**
     * Когда ширина экрана меньше чем
     */
    less: Number,
    /**
     * Когда ширина экрана больше чем
     */
    more: Number,
  },
  render: (h, ctx, breakpoints, point) => {
    const width = breakpoints[point]
    const { less, more } = ctx.props

    if (!less && !more) return undefined
    if (less && width > less) return ctx.scopedSlots.otherwise && ctx.scopedSlots.otherwise()
    if (more && width < more) return ctx.scopedSlots.otherwise && ctx.scopedSlots.otherwise()

    return Point.render(h, ctx, breakpoints, point)
  }
}

/**
 * Генерирует "скрывателя" основанного на конкретном ширине экрана
 * @param {BreakPointsObject} breakpoints Обьект точек
 * @param {string} point ключ параметра "ширины"
 * @returns {VueFunctionalComponent} Функциональный vue компонент
 */
export const createByWidth = (breakpoints, point) => ({
  extends: Width,
  render: (h, ctx) => Width.render(h, ctx, breakpoints, point)
})