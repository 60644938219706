import { BaseApp } from "./base"

class MainApp extends BaseApp {

  /**
   * Шаблон приложения
   * 
   * Делаем многоступенчатый рендеринг приложения.
   * 
   * Необходимо для того чтобы:
   * 
   * - Если приложение закрыто "заглушкой",
   *   то рендерим только "заглушку" и больше ничего, чтобы не "захламлять"
   *   браузер пользователя;
   * 
   * - Если пользователь пытается зайти с браузера IE, тогда, из статики
   *   браузер скачает только заглушку и проверку на IE, потому что больше
   *   не имеет смысла пользователю отправлять.
   * 
   * - Ну а если приложение "не заглушено" и браузер пользователя не IE,
   *   тогда асинхронно подгружается компонент "приложение"
   *   и все показывается в обычном режиме.
   * 
   * Плюсы такого подхода, мы не отправляем пользователю
   * лишний/тяжеловесный код, мы не запрашиваем /i, matomo и прочее,
   * тем самым не "атакуем" сервер не нужными запросами.
   */
  get template () {
    return `
      <maintenance #default>
        <i-e-forbid #default>
          <auth />
        </i-e-forbid>
      </maintenance>
    `
  }

  /**
   * Компоненты приложения
   */
  get components () {
    return {
      Maintenance: () => import(/* webpackChunkName: "services/Maintenance" */ "services/Maintenance"),
      IEForbid: () => import(/* webpackChunkName: "services/BrowserDetector/IEForbid" */ "services/BrowserDetector/IEForbid"),
      Auth: () => import(/* webpackChunkName: "App/Index" */ "./App/index")
        .then(({ runAuthApp }) => runAuthApp()),
    }
  }
}

new MainApp()