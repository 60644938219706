import { hyphenate, isObject } from "plugs/utils"
import colors from 'styles/settings/colors/index.module.scss';
import defaultColors from './defaultColors'
import variables from 'styles/settings/variables.module.scss';
import defaultVariables from 'styles/settings/defaultVariables';

const replacePropKey = (props) => {
  const changedColorKeys = Object.entries(props).map(
    ([key, value]) => {
      const separator = '---';
      const [groupKey, subKey] = key.split(separator);
      let currentKey = key.replace(separator, ' ');

      if (subKey === 'base') {
        currentKey = groupKey;
      }

      return [currentKey, value];
    },
  );

  return Object.fromEntries(changedColorKeys);
};
export const groupProps = (props, config = {}) => {
  const { excludeGroupsKey = false, separator = ' '} = config;
  const excludedGroups = ['shades'];
  const excludedSubKeys = ['base'];
  const uniqueKeys = ['ungrouped'];

  return Object.entries(props).reduce((acc, [key, value]) => {
    const [groupKey, subKey] = key.split(separator);
    const isExcludedGroupsKey = excludeGroupsKey || excludedGroups.includes(groupKey);
    const isExcludedSubsKey = excludedSubKeys.includes(subKey);
    const isUniqueKey = uniqueKeys.includes(groupKey);
    let currentKey = key;

    if (isUniqueKey) {
      acc[subKey] = value;

      return acc;
    }
    if (isExcludedGroupsKey) currentKey = subKey;
    if (isExcludedSubsKey) currentKey = groupKey;

    acc[groupKey] = {
      ...acc[groupKey],
      [currentKey]: value,
    }

    return acc;
  }, {});
};

export const _colors = replacePropKey(colors ?? defaultColors);

export const groupedColors = groupProps(_colors);
const groupedVariables = groupProps(variables ?? defaultVariables, { excludeGroupsKey: true, separator: '---' });

export const Colors = {
  install: function (Vue) {
    Object.defineProperties(Vue.prototype, {
      $colors: {
        value: _colors,
        enumerable: false,
        configurable: false,
        writable: false,
      },
    });

    Object.defineProperties(Vue.prototype, {
      $cssVars: {
        value: groupedVariables,
        enumerable: false,
        configurable: false,
        writable: false,
      },
    });
  }
}

/**
 * "Выцепляет" переменные из sass vars
 * Если значение объект, то так же возвращается объект
 * 
 * @param {string} name Имя ключа в camelCase
 * @param {boolean} isDeleteProductUnits Флаг сообщающий о необходимости удаления единиц измерения у объекта
 * @returns {number|object} Значения sass var переменной
 */
export const getVarValue = (name, isDeleteProductUnits = false) => {
  const propName = `$${ hyphenate(name) }`;
  const currentValue = groupedVariables[propName];

  if (isDeleteProductUnits && isObject(currentValue)) {
    return Object.fromEntries(Object.entries(currentValue).map(([key, value]) => [key, parseInt(value)]))
  }

  return currentValue;
}

/**
 * Тулинг для трансформации цвета
 */
export * from "./transformColor"