import Vue from "vue"
import { isUndefined, isFunction } from "plugs/utils"

/**
 * Функциональный "прокси" компонент.
 * Рендерит компоненты в зависимости от признака "мобильности"
 * 
 * @typedef {object} VueComponent
 * @param {VueComponent} cDesktop Компонент для настольной версии
 * @param {VueComponent} cMobile Компонент для мобильной версии
 * @returns {VueComponent} Компонент
 */
const functionalProxy = (cDesktop, cMobile, cb) => {
  /**
   * Нормализуем callBack,
   * по-умолчанию смотрим на параметр "mobile"
   */
  const _cb = isFunction(cb) ? cb : ({ onMobile }) => onMobile
  return {
    name: "breakpoint-swapper",
    functional: true,
    props: {
      /**
       * Отображать всегда только мобильную версию компонента
       */
      alwaysMobile: Boolean,

      /**
       * Отображать всегда только браузерную версию компонета
       */
      alwaysBrowser: Boolean,
    },
    render (h, { props, data, children }) {

      const { alwaysBrowser } = props
      if (alwaysBrowser && cDesktop) return h(cDesktop, data, children)

      const { alwaysMobile } = props
      if (alwaysMobile && cMobile) return h(cMobile, data, children)

      const {
        $breakpoints
      } = Vue.prototype

      const isMobile = _cb($breakpoints)
      
      if (isUndefined(isMobile)) return undefined

      return h(isMobile ? cMobile : cDesktop, data, children)
    }
  }
}

export default functionalProxy
