import { hyphenate } from "plugs/utils"

/**
 * @typedef {object} BreakPointsObject
 * @typedef {object} VueFunctionalComponent
 * 
 * @author Lobkov Alexandr <aslobko1@mts.ru>
 */

export const Point = {
  functional: true,
  props: {
    /**
     * Тэг элемента обертки
     */
    tag: {
      type: String,
      default: "div"
    },
    /**
     * Имя тэга для текстовых vnode
     */
    textTag: {
      type: String,
      default: "span"
    },
    /**
     * Режим прозрачности, как будто используется `v-if`
     */
    transparent: {
      type: Boolean,
    }
  },
  /**
   * В случае когда текущий `breakpoint` не совпадает с тем на который расчитан компонент
   * через слот `otherwise` мы дадим возможность отобразить произвольный текст(типа `иначе`).
   * @slot otherwise
   */
  render (h, ctx, breakpoints, point) {

    if (!breakpoints[point]) return ctx.scopedSlots.otherwise && ctx.scopedSlots.otherwise()

    const { children } = ctx

    if (!children) return undefined

    const { transparent } = ctx.props

    if (transparent) return children

    const {
      data,
      props: {
        tag,
        textTag
      }
    } = ctx

    return h(
      tag,
      data,
      children.map(child => {
        if (child.text && textTag) return h(textTag, [ child ])
        return child
      })
    )
  }
}

/**
 * Генерирует "скрывателя" основанного на конкретном "breakpoint"
 * @param {BreakPointsObject} breakpoints Обьект точек
 * @param {string} point Название точки, например "xs", "smAndUp"
 * @returns {VueFunctionalComponent} Функциональный vue компонент
 */
export const createByPoint = (breakpoints, point) => ({
  extends: Point,
  name: `breakpoint-hider-${ hyphenate(point) }`,
  render: (h, ctx) => Point.render(h, ctx, breakpoints, point)
})