/**
 * Трансформация через дефис
 * 
 * @param {String} str Строка
 * @return {String} Строка через дефис в нижнем регистре
 */
export const hyphenate = (str = "") => str.replace(/\B([A-Z])/g, '-$1').toLowerCase()

/**
 * Строка в camel case
 * 
 * @param {String} str Строка
 * @return {String} Строка в camel case
 */
export const camelize = (str = "") => str.replace(/-(\w)/g, (_, c) => c ? c.toUpperCase() : '')

/**
 * Строка с заглавной буквы
 * 
 * @param {String} str Строка
 * @return {String} Строка с заглавной буквы
 */
export const capitalize = (str = "") => str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Фио в формате Иванов И.И.
 *
 * @param {Object} obj Строка
 * @return {String} Строка с заглавной буквы
 */
export const fullNameWithInitial = (obj = {}) => {
  const { first_name, last_name, other_name } = obj

  let personFullName = last_name || 'Неизвестный'

  if (first_name) {
    personFullName += ` ${first_name.charAt(0)}.`
  }

  if (first_name && other_name) {
    personFullName += `${other_name.charAt(0)}.`
  }

  return personFullName
}

/**
 * Убирает дубликаты из массива
 * @param {array} arr Массив значений
 * @returns {array} Массив уникальных значений
 */
export const unique = arr => arr.filter((el, i, self) => self.indexOf(el) === i)

/**
 * Возвращает значение с лидирующим нулём
 *
 * @param {(string|int)} value - Значение
 * @return {(string|ing)}
 */
export const checkLeadZero = v => v < 10 ? '0' + v : v

/**
 * Проверка браузера на поддержку localStorage
 *
 * @returns {bool} Результат проверки
 */
export const supports_html5_storage = function () {
  try {
    return 'localStorage' in window && window['localStorage'] !== null
  } catch (e) {
    return false
  }
}

/**
 * Позволяет выводить одну из форм множественности для дробного числа.
 * 
 * @param {number} digit Дробное число для которого надо определить форму множественности
 * @param {string} form1 Форма множественности при значении .5 < digit > .5
 * @param {string} form2 Форма множественности при значении .5
 * @returns {string} Форма множественной строки
 */
const fractilize = (digit, form1, form2) => {
  if (digit < .5 || digit > .5) return form1
  return form2
}

/**
 * Позволяет выводить одну из форм множественности.
 * @See https://gist.github.com/tomfun/830fa6d8030d16007bbab50a5b21ef97
 * 
 * Например, 1 человек, 2 человека, 5 человек
 * 
 * @param {number} number Число для которого надо определить форму множественности
 * @param {string} number Форма множественности при значении 1
 * @param {string} number Форма множественности при значении 2
 * @param {string} number Форма множественности при значении 5
 * @returns {string} Форма множественной строки
 */
export const pluralize = (number, one, two, five) => {
  let n = Math.abs(number);

  const fraction = n - Math.trunc(n)

  n %= 100;
  if (n >= 5 && n <= 20) {
    return fraction ? fractilize(fraction, two, five) : five
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 1 && n <= 4) {
    return fraction ? fractilize(fraction, two, two) : two;
  }

  return fraction ? fractilize(fraction, two, two) : five
}

/**
 * Функция возвращает флаг поддержки touch экрана у пользователя
 * @see https://stackoverflow.com/a/4819886
 * @return {boolean}
 */
export const isTouchDevice = () => ((navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
// export const isTouchDevice = () => (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))

/**
 * Функция вычисляет высоту на основании переданной ширини и соотношения сторон
 * @param {number} width Ширина
 * @param {number} x Сторона X
 * @param {number} y Сторона Y
 * @returns {number} Высота
 */
export const getAspectRatioHeight = (width, x, y) => (
  (width / x) * y
)

/**
 * Функция вычисляет ширину на основании переданной высоты и соотношения сторон
 * @param {number} height Высота
 * @param {number} x Сторона X
 * @param {number} y Сторона Y
 * @returns {number} Ширина
 */
export const getAspectRatioWidth = (height, x, y) => (
  (height / y) * x
)