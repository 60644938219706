import { getSplitDate, _toDate } from "./index"

/**
 * Базовая функция для сравнения дат.
 * 
 * Основная логика работы, перебираются все переданные аргументы(даты),
 * каждая дата разбивается на составляющие (год, день, месяц, часы, минуты, секунды).
 * 
 * "Разбитую" дату передаем на вход cb функции, задача которой вернуть необходимую
 * строку для последующего сравнения, например, чтобы проверить одинаковый месяц,
 * cb возвращает для каждого аргументы(даты) строку вида "20219"(${ Y }${ M }),
 * т.е. просто склеиные воедино числа года и месяца.
 * 
 * Все что вернет cb, складывается в массив.
 * 
 * После этого обработчик массив "every", проверить что все эти строки равны и
 * вернет результат.
 * 
 * Сравнение по строкам было выбрано по причине того, что если нам надо сравнить
 * например по дням(все даты одного дня), то нам надо сравнивать не только цифру
 * "дня", но и конечно же "год" и "месяц".
 * 
 * @param {function} cb Функция которая возвращает необходимую для сравнения строку
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean} Одинаковые или нет
 */
export const isSamed = function (cb, ...args) {
  return args
    /**
     * Побежим через reduce, потому что попутно отфильтруем если передали
     * "невалидную" дату.
     */
    .reduce((acc, date) => {
      let _split = getSplitDate(_toDate(date))
      if (!_split) return acc

      acc.push(cb(_split))
      return acc
    }, [])
    .every((v, i, vs) => {
      if (i === 0) return true
      // Сравним последующий с предыдущим
      return v === vs[i - 1]
    })
}

/**
 * Проверяет что все переданные даты одной минуты
 * 
 * @example
 * isSameMinutes(
 *   new Date(2021, 10, 1, 1, 10),
 *   new Date(2021, 10, 1, 2, 11),
 *   new Date(2021, 10, 1, 3, 1),
 * )
 * 
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean}
 */
export const isSameMinutes = function (...args) {
  const cb = ({ Y, M, D, H, m }) => `${ Y }${ M }${ D }${ H }${ m }`
  return isSamed(cb, ...args)
}

/**
 * Проверяет что все переданные даты одного часа
 * 
 * @example
 * isSameHour(
 *   new Date(2021, 10, 1, 12),
 *   new Date(2021, 10, 1, 2),
 *   new Date(2021, 10, 1, 23),
 * )
 * 
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean}
 */
export const isSameHour = function (...args) {
  const cb = ({ Y, M, D, H }) => `${ Y }${ M }${ D }${ H }`
  return isSamed(cb, ...args)
}

/**
 * Проверяет что все переданные даты одного дня
 * 
 * @example
 * isSameDay(
 *   new Date(2021, 10, 1),
 *   new Date(2021, 10, 2),
 *   new Date(2021, 10, 3),
 * )
 * 
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean}
 */
export const isSameDay = function (...args) {
  const cb = ({ Y, M, D }) => `${ Y }${ M }${ D }`
  return isSamed(cb, ...args)
}

/**
 * Проверяет что все переданные даты одного месяца
 * 
 * @example
 * isSameMonth(
 *   new Date(2021, 2),
 *   new Date(2021, 1),
 *   new Date(2021, 9),
 * )
 * 
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean}
 */
export const isSameMonth = function (...args) {
  const cb = ({ Y, M }) => `${ Y }${ M }`
  return isSamed(cb, ...args)
}

/**
 * Проверяет что все переданные даты одного года
 * 
 * @example
 * isSameYear(
 *   new Date(2021),
 *   new Date(2019),
 *   new Date(1999),
 * )
 * 
 * @param  {...any} args Параметры дат которые надо проверить
 * @returns {boolean}
 */
export const isSameYear = function (...args) {
  const cb = ({ Y }) => `${ Y }`
  return isSamed(cb, ...args)
}