import {
  getType as _getType,
  isArray as _isArray,
  isBoolean as _isBoolean,
  isFunction as _isFunction,
  isNull as _isNull,
  isNumber as _isNumber,
  isObject as _isObject,
  isPromise as _isPromise,
  isString as _isString,
  isUndefined as _isUndefined,
} from "@beast/utils"

/**
 * Для более комфортной разработки,
 * модифицируем все функции "@beast/utils"
 * на возможность обрабатывать несколько аргументов.
 * @ignore
 */
const devComfortableProxy = handler => (
  // Для красивого вывода в консоль вернем именнованную функцию :)
  function devComfortable (...args) {
    return args.every(handler)
  }
)

/*
 * Проверяет что параметр является массивом
 */
export const isArray = devComfortableProxy(_isArray)

/*
 * Проверяет что параметр является пустым массивом
 */
export const isEmptyArray = devComfortableProxy(v => (
  !(_isArray(v) && v.length)
))

/*
 * Проверяет что параметр булевого типа
 */
export const isBoolean = devComfortableProxy(_isBoolean)

/*
 * Проверяет что параметр является функцией
 */
export const isFunction = devComfortableProxy(_isFunction)

/*
 * Проверяет что параметр равен null
 */
export const isNull = devComfortableProxy(_isNull)

/*
 * Проверяет что параметр является числом
 */
export const isNumber = devComfortableProxy(_isNumber)

/*
 * Проверяет что параметр является объектом
 */
export const isObject = devComfortableProxy(_isObject)

/*
 * Проверяет что параметр является пустым объектом
 */
export const isEmptyObject = devComfortableProxy(v => (
  !(_isObject(v) && Object.keys(v).length)
))

/*
 * Проверяет что параметр является промисом
 */
export const isPromise = devComfortableProxy(_isPromise)

/*
 * Проверяет что параметр является строкой
 */
export const isString = devComfortableProxy(_isString)

/*
 * Проверяет что параметр является undefined
 */
export const isUndefined = devComfortableProxy(_isUndefined)

/*
 * Проверяет что параметр undefined/null/пустая строка
 */
export const isEmpty = devComfortableProxy(v => (
  isUndefined(v) || isNull(v) || (isString(v) && v === "")
))

/*
 * Проверяет что параметр является Native File Object
 */
export const isFile = devComfortableProxy(v => (
  _getType(v) === 'file'
))

export const isError = devComfortableProxy(v => (
  _getType(v) === 'error'
))

/*
 * Проверяет что параметр является Blob
 */
export const isBlob = devComfortableProxy(v => (
  _getType(v) === 'blob'
))

/*
 * Проверяет что параметр является Webpack Module
 */
export const isModule = devComfortableProxy(v => (
  _getType(v) === 'module'
))

/**
 * Дореекспортим для обратной совместимости
 * если что-то забыл!
 * @ignore
 */
export * from "@beast/utils"

/**
 * Проверка существования значения в массиве
 *
 * @param {array} array - Входной массив
 * @param {mixed} search - Что искать в массиве
 * @returns {bool} Результат поиска
 */
export const isInArray = (array, search) => array.indexOf(search) >= 0