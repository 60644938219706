import * as common from "./Common"
// Реекспортим для обратной совместимости
export * from "./Common"

/**
 * Работа с типами
 */
import * as typeDefs from "./Types"
// Реекспортим для обратной совместимости
export * from "./Types"

/**
 * Константы для дат
 */
import * as constantsDates from "./Dates/constants"
export * from "./Dates/constants"

/**
 * Общие методы представляения дат
 */
import * as representationDates from "./Dates/index"
export * from "./Dates/index"

/**
 * Работа с похожими датами
 */
import * as sameDate from "./Dates/same"
// Реекспортим для обратной совместимости
export * from "./Dates/same"

/**
 * Манипуляции с датами
 */
import * as manipulateDate from "./Dates/manipulate"
// Реекспортим для обратной совместимости
export * from "./Dates/manipulate"

/**
 * Проверка экземпляра
 */
export * as instance from "./Instance"

/**
 * Специфические методы
 */
export * as specific from "./Specific"

/**
 * Методы для работы с классами цветов
 */
export * from "./Colors"

/**
 * Сортировка
 */
export * from "./Sort"

const HotUtils = {
  install: Vue => {
    Vue.prototype.$HotUtils = {
      ...common,
      ...typeDefs,
      ...sameDate,
      ...manipulateDate,
      ...constantsDates,
      ...representationDates
    }
  }
}

export { HotUtils }
