/**
 * Проверяет является ли нода пустой,
 * включая всех детей, если таковы имеются.
 * 
 * @param {VNode} vnode 
 * @returns {boolean}
 */
export const isNotEmptyNode = vnode => {
  let output = [
    !!(vnode.text && vnode.text.trim()),
    !!vnode.componentOptions,
    !!vnode.componentInstance
  ]

  if (vnode.children) {
    const childIsEmpty = vnode.children.some(isNotEmptyNode)
    output.push(childIsEmpty)
  }

  return output.some(b => b)
}