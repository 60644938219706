import { isNumber, isString } from "../Types"
import { pluralize } from "mix/Plural"

const plyHours = v => `${ v } ${ pluralize(v, "час", "часа", "часов") }`
const plyMinutes = v => `${ v } ${ pluralize(v, "минута", "минуты", "минут") }`

/**
 * Округляет длительность по следующему принципу:
 * 
 * Минуты остаются как есть:
 * 8 минут - 8 минут
 * 43 минуты - 43 минуты
 * 
 * Округляем минуты до получаса и часа
 * 1 час - 1 час
 * 1ч 5 мин - ~ 1 час
 * 1ч 18 мин - ~ 1,5 часа
 * 1,5 часа - 1,5 часа
 * 1ч 48 мин - ~ 2 часа
 * 2 часа - 2 часа
 *
 * @param {string|number} duration Кол-во минут
 * @returns {string}
 */
export const duration = duration => {
  if (!duration) return undefined

  if (isString(duration)) {
    if (/\D+/.test(duration.trim())) return undefined
    duration = parseInt(duration.trim())
  }

  if (!isNumber(duration) || isNaN(duration)) return undefined

  const hours = Math.trunc(duration / 60)
  if (!hours) return plyMinutes(duration)

  const minutes = duration - hours * 60

  if (!minutes) return plyHours(hours)
  if (minutes < 14) return `~ ${ plyHours(hours) }`

  if (minutes === 30) return plyHours(hours + 0.5)

  if (minutes >= 15 && minutes < 44) return `~ ${ plyHours(hours + 0.5) }`
  if (minutes >= 45) return `~ ${ plyHours(hours + 1) }`

  return `${ plyHours(hours) } ${ plyMinutes(minutes) }`
}