import { Envelopment } from "./Envelopment"
import { initShowers } from "./showers"

/**
 * Выложим переменную окружения,
 * если надо воспользоваться до
 * рендера приложения.
 */
export let $env = undefined

const initEnv = {
  install: (Vue, options) => {

    const { envs } = options ?? {}

    if (!envs) {
      throw new Error("Envelopment: envs is required")
    }

    $env = new Envelopment(envs)

    Object.defineProperty(Vue.prototype, "$env", {
      value: $env,
      enumerable: false,
      configurable: false,
      writable: false,
    })

    Vue.use(initShowers)
  }
}

export { initEnv }
