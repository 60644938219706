/**
 * Набор "скрывателей"
 * 
 * Для каждого свойства из набора компонента `../breakPoint`
 * Создается одноименный компонент с префиксом "show-...",
 * который показывает все что в него передали в $slot.default если
 * свойство из `../breakPoint` положительно и скрывает если ложно.
 * 
 * Названия компонентов вида <show-xs />, <show-md-only />, <show-xl-and-down />, <show-width />
 * 
 * @typedef {object} BreakPointsObject
 * @typedef {object} VueFunctionalComponent
 * 
 * @author Lobkov Alexandr <aslobko1@mts.ru>
 */

import { getVarValue } from "plugs/colors"
import { isObject, hyphenate } from "plugs/utils"

import { createByPoint } from "./point"
import { createByWidth } from "./width"

/**
 * Возвращает массив ключей из { ./breakPoint }.$data на основе ключей
 * обявленных в src/assets/css/styles/settings/_variables.scss
 * @param {array} breakKeys Массив ключей "breakpoint'ов"
 * @returns {array} Наблор ключей из { ./breakPoint }.$data
 */
const getPoints = breakKeys => {
  const points = getVarValue("gridBreakpoints")
  // Catch empty value
  if (!points && !isObject) return []

  // TODO: Подумать как локаничней сделать список разрешенных ключей
  const sassKeys = [ ...Object.keys(points), "onMobile", "onDesktop" ]

  const _ret = breakKeys.filter(pointKey => (
    sassKeys.some(sKey => pointKey.includes(sKey))
  ))
  return _ret
}

export const PREFIX = "show-"

export const Hiders = {
  install: (Vue, breakpoints) => {
    const breakKeys = Object.keys(breakpoints)
    const points = getPoints(breakKeys)

    /**
     * Регистрируем глобально все компоненты для точек.
     */
    points.forEach(p => Vue.component(
      `${ PREFIX }${ hyphenate(p) }`,
      createByPoint(breakpoints, p)
    ));
    
    /**
     * Регистрируем отдельно компонент скрытия зависимый от ширины экрана
     */
    Vue.component(
      `${ PREFIX }${ hyphenate("width") }`,
      createByWidth(breakpoints, "width")
    )
  }
}