import { common } from "@/config"

import { warn } from "plugs/warn"
const _log = warn(`Breakpoints helpers: <${ module.id }>`)

const toNumber = num => {
  const _num = Number(num)
  return !isNaN(_num) ? _num : undefined
}

/**
 * Расчитывает максимально возможную ширину, основываясь на переданном значении.
 * 
 * Переданное значение воспринимается как максимальное для
 * базовой максимальной ширины заданной дизайнером, по состоянию на 30.11.2020
 * оно составляет 1920(px).
 * 
 * @typedef {object} BreakPointsObject
 */

/**
 * Функция конструктор расчета
 * @param {BreakPointsObject} datas Набор точек из { ./breakPoint }.$data
 * @returns {getMaxWidthInverse} Функция вычисления обратной зависимости
 */

const getInverseOnDepRefMax = datas => {
  const { reference: { maxWidth = 1920 } } = common || {}

  /**
   * Вычисляет "обратную" зависимость от максимальной величины,
   * но не более `maxTreshold` и не менее `w`
   * @example
   *  1920 >>> 28
   *  886 >>> 60
   * @param {number} w Максимально возможная величина
   * @param {number} maxTreshold {default: 95} Максимально возможный предел
   * @param {number} max Базовая величина от которой будет расчет, например если ширина то 1920px
   * @param {string} prop Свойство из $breakpoints по которому делать подсчет
   */
  const _getMaxInverse = function (val, maxTreshold = 95, max = maxWidth, prop) {

    const _val = toNumber(val)

    if (!_val) {
      _log("Не удалось преобразовать переданное значение в число!")
      return val
    }

    const { [ prop ]: breakProp } = datas
    let _ret = Math.floor((_val * max) / breakProp)

    if (_ret < _val) return _val
    if (_ret > maxTreshold) return maxTreshold

    return _ret
  }

  /**
   * Вычисляет "прямую" зависимость от максимальной величины,
   * но не менее `minTreshold` и не менее `val`
   * @example
   *  1920 >>> 28
   *  886 >>> 14
   * @param {number} val Величина при размере @prop "max"
   * @param {number} minTreshold {default: 10} Минимально возможный предел
   * @param {number} max Базовая величина от которой будет расчет, например если ширина то 1920px
   * @param {string} prop Свойство из $breakpoints по которому делать подсчет
   */
  const _getMax = function (val, minTreshold = 10, max = maxWidth, prop) {

    const _val = toNumber(val)

    if (!_val) {
      _log("Не удалось преобразовать переданное значение в число!")
      return val
    }

    const { [ prop ]: breakProp } = datas
    let _ret = (_val * breakProp) / max

    if (_ret > _val) return _val
    if (_ret < minTreshold) return minTreshold

    return _ret
  }

  /**
   * Вычисляет ближайшую точку сетки из переданных
   * @param {array} names Точки сетки
   * @return {string} Имя ближайшей точки из `names`
   */
  const closest = function (names) {
    if (!Array.isArray(names)) return datas.name
    const goal = datas.width
    const namesWidth = names.reduce((acc, name) => {
      acc[ datas.thresholds[name] ] = name
      return acc
    }, {})
    
    const closest = Object.keys(namesWidth).reduce((prev, curr) => (
      (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
    ))
    
    return namesWidth[closest]
  }

  return {
    getMaxWidthInverse: (v, treshold) => _getMaxInverse(v, treshold, maxWidth, "width"),
    getMaxWidth: (v, treshold) => _getMax(v, treshold, maxWidth, "width"),
    getMaxHeightInverse: (v, treshold, height) => _getMaxInverse(v, treshold, height, "height"),
    getMaxHeight: (v, treshold, height) => _getMax(v, treshold, height, "height"),
    closest
  }
}

export const helpers = {
  install: (Vue, points) => {

    const depProps = getInverseOnDepRefMax(points)

    Object
      .keys(depProps)
      .forEach(propKey => {
        Object.defineProperty(points, propKey, {
          enumerable: false,
          configurable: false,
          writable: false,
          value: depProps[propKey]
        })    
      });
  }
}
