/**
 * @see ./Readme.md
 */

import { hyphenate } from "plugs/utils"

export const showerPrefix = "env-show-"

const shower = (name, value) => ({
  name,
  functional: true,
  render: (h, { data, scopedSlots }) => {
    if (!value) return null
    return h("div", data, scopedSlots.default())
  }
})

const initShowers = {
  install: Vue => {
    const { prototype: { $env } } = Vue

    const stands = $env.list

    Object.keys(stands).forEach(env => {

      const name = `${ showerPrefix }${ hyphenate(env) }`
      const isShow = stands[env]      
      Vue.component(name, shower(name, isShow))

      const prfx = $env.prefix

      const isNotName = name.replace(prfx, `${ prfx }-not`)
      const isNotShow = !isShow
      
      Vue.component(isNotName, shower(isNotName, isNotShow))
    })
  }
}

export { initShowers }