/**
 * Проверяет что цвет предназначен для текста, т.е. имеет текстовый формат
 * 
 * @param {string} color Цвет
 * @return {boolean}
 */
export const colorIsTextFormat = color => (
  [ '--text', 'text--' ].some(mark => color.includes(mark))
)

/**
 * Ковертирует цвет в текстовый формат
 * 
 * @param {string} color Цвет
 * @return {string} Цвет в текстовом формате
 */
export const colorToTextFormat = color => {
  let [ first, second ] = color.split(" ")
  let output = `${ first }--text`
  if (second) output += ` text--${ second }`
  return output
}

/**
 * Ковертирует текстовый формат цвета в формат цвета для фона
 * 
 * @param {string} color Цвет
 * @return {string} Цвет в формате фоновом формате
 */
export const colorFromTextFormat = color => (
  color.trim().replace("--text", "").replace("text--", "")
)