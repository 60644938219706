import { isFunction } from "../Types";

/**
 * Сортировка по локали
 * @see https://ru.stackoverflow.com/a/971349
 * 
 * !!! Важно, возвращает копию массива
 * 
 * Сначала с хэштегами, потом русские от А до Я, потом английские от A до Z
 * 
 * @param {array} arr Сортируемый массив 
 * @param {function} getter Геттер для значение если элемент массива обьект
 * @param {string} locale Строка с языковой меткой BCP 47, либо массив таких строк. @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator 
 * @returns {array}
 */
export const sortCyrlLatn = (arr, getter, locale = "ru") => {

  if (!Array.isArray(arr)) return arr

  const collator = new Intl.Collator(locale);

  if (!isFunction(getter)) getter = v => v

  return [ ...arr ].sort((a, b) => {
    const aValue = getter(a)
    const bValue = getter(b)
    if(isNaN(aValue) && !isNaN(bValue)){
      return -1
    }else if(!isNaN(aValue) && isNaN(bValue)){
      return 1
    }
    return collator.compare(aValue, bValue);
  });
}