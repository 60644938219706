import { warn } from "plugs/warn"
const _log = warn(`Transform color utils: ${ require.resolve("./utils") }`)

/**
 * @typedef {number} ColorInt
 * @typedef {string | number | {}} Color
 */

/**
 * colorToInt
 * @param {Color} color 
 * @returns {ColorInt}
 */
export function colorToInt (color) {
  let rgb

  if (typeof color === 'number') {
    rgb = color
  } else if (typeof color === 'string') {
    let c = color[0] === '#' ? color.substring(1) : color
    if (c.length === 3) {
      c = c.split('').map(char => char + char).join('')
    }
    if (c.length !== 6) {
      _log(`'${color}' is not a valid rgb color`)
    }
    rgb = parseInt(c, 16)
  } else {
    throw new TypeError(`Colors can only be numbers or strings, recieved ${color == null ? color : color.constructor.name} instead`)
  }

  if (rgb < 0) {
    _log(`Colors cannot be negative: '${color}'`)
    rgb = 0
  } else if (rgb > 0xffffff || isNaN(rgb)) {
    _log(`'${color}' is not a valid rgb color`)
    rgb = 0xffffff
  }

  return rgb
}

/**
 * Integer to HEX 
 * @param {ColorInt} color 
 * @returns {string}
 */
export function intToHex (color) {
  let hexColor = color.toString(16)

  if (hexColor.length < 6) hexColor = '0'.repeat(6 - hexColor.length) + hexColor

  return '#' + hexColor
}

/**
 * Color to HEX
 * @param {Color} color 
 * @returns {string}
 */
export function colorToHex (color) {
  return intToHex(colorToInt(color))
}