/**
 * Устанавливает фавиконку приложения в зависимости
 * от темы браузера. Если тема не определена
 * установим как для "светлой" темы.
 */

/**
 * Если установлена темная тема, возвращает true иначе false.
 * 
 * @param {string} name Имя темы
 * @returns {boolean}
 */
const prefersDarkTheme = () => (
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
)

/**
 * Возвращает ссылку на DOM элемент по типу иконки
 * @param {string} type Тип иконки
 * @returns DOM элемент
 */
const getFavicon = type => {
  let $favicons = document.querySelectorAll('link[rel*="icon"]')
  let moveToArr = Array.prototype.slice.call($favicons)
  return moveToArr.find(({ type: nativeT }) => nativeT === type)
}

/**
 * Обновляет/Задает путь фавиконке
 * 
 * @see https://stackoverflow.com/a/35960429
 * 
 * @param {string} path Путь иконки
 * @param {string} type Тип иконки
 */
const updateFavicon = (path, type) => {
  let $favicon = getFavicon(type)

  if ($favicon !== undefined) {
    $favicon.href = path
  } else {
    $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = path
    $favicon.type = type
    document.head.appendChild($favicon)
  }
}

/**
 * Обновляет/Задает путь SVG фавиконке
 * 
 * @param {string} path Путь к иконке
 */
const updateSVGFavicon = path => updateFavicon(path, "image/svg+xml")

/**
 * Обновляет/Задает путь PNG фавиконке
 * 
 * @param {string} path Путь к иконке
 */
const updatePNGFavicon = path => updateFavicon(path, "image/png")

/**
 * Устанавливает "темную" фавиконку
 */
const setDarkFav = () => {
  updateSVGFavicon(require('./common.svg'))
  updatePNGFavicon(require('./common.png'))
}

/**
 * Устанавливает "светлую" фавиконку
 */
const setLightFav = () => {
  updateSVGFavicon(require('./common.svg'))
  updatePNGFavicon(require('./common.png'))
}

/**
 * Устанавливает фавиконку в зависимости от темы
 */
const setFavicon = () => {
  return prefersDarkTheme() ? setDarkFav() : setLightFav()
}

/**
 * Инициализирует установку фавиконки
 */
export const initFavicon = {
  install: () => {
    /**
     * Сперва наперво, поставим иконку в зависимости от темы.
     */
    setFavicon()
    
    /**
     * После, подпишемся на событие изменения темы.
     */
    window.matchMedia('(prefers-color-scheme: dark)').addListener(setFavicon)  
  }
}