import { pluralize } from 'plugs/utils'

export { pluralize }

export default {
  methods: {
    pluralize,
    /* Example...
      pluCourse (count) {
        return this.pluralize(count, 'курс', 'курсы', 'курсов')
      },
    */
  },
}
