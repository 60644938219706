import cfg from '@/config'

import { isFunction, isString, capitalize } from "plugs/utils"

import { warn } from "plugs/warn"
const _log = warn(`Envelopment class: <${ module.id }>`)

export class Envelopment {

  constructor (config) {

    this.config = config ?? {}

    this.list = {}

    this.initTag()
    this.initStands()
  }

  get processEnv () {
    return process.env
  }

  /**
   * Префикс
   */
  get prefix () {
    return "is"
  }

  /**
   * Возвращает название с префиксом
   * @param {string} name 
   * @returns {string}
   */
  withPrefix (name) {
    return this.prefix + name
  }

  /**
   * Возвращает статус окружения
   * @param {string} name Имя окружения, eg. Prod, Stage, Dev, local, Mobile
   */
  is (name) {
    if (!name) return false

    name = name.toString()

    if (name === name.toLowerCase()) {
      return _log("Имя окружения должно начинаться с большой буквы")
    }

    return this.current === name
  }

  /**
   * @see https://jira.mts.ru/browse/ABPBEAST-2152#comment-4223192
   * 
   * Для локального стенда будет префикс `dev`.
   */
  get bucketPrefixEnv () {
    //по просьбе бэка education-бакет применяем только для прода
    //для внешек дева и стейджа используем бакеты дева и стейджа
    const externalHack =()=> {
      const origin = window.location.origin

      if (origin.includes('education.mts.ru')) {
        return 'education'
      } else {
        return origin.includes('external.dev') ? 'dev' : 'demo'
      }
    }

    switch (this.current) {
      case "Local":     return "dev"
      case "Dev":       return "dev"
      case "Stage":     return "demo"
      case "Prod":      return "prod"
      case "External":  return externalHack()
      default:          return ""
    }
  }

  bucketGetName (name) {
    return `${ cfg.common.localStorageKey }-${ this.bucketPrefixEnv }-${ name }`
  }

  initTag () {
    this.tag = this.processEnv.VUE_APP_TAG
  }

  initStands () {

    Object.entries(this.config).forEach(([ stand, host ]) => {
      
      stand = capitalize(stand)

      const prefixStand = this.withPrefix(stand)
      const standStatus = this.matchHost(host)

      this[prefixStand] = standStatus

      // Установим текущий стенд
      if (this[prefixStand]) {
        this.current = stand
      }

      // Соберем все стенды со статусами в общий список
      this.list[prefixStand] = standStatus
    })
  }

  matchHost (host) {
    const origin = window.location.origin

    if (isString(host)) return origin.includes(host)
    if (isFunction(host)) return host(origin)
    return false
  }
}