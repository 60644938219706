// Сторибук не умеет в модули, при undefined будем использовать словарь

export default {
  "white---base": "#FFFFFF",
  "white---smoke": "#F2F3F7",
  "white---milk": "#E2E5EB",
  "red---base": "#FF0032",
  "red---deep": "#C8050F",
  "red---soft": "rgba(249, 87, 33, 0.15)",
  "blue---base": "#002094",
  "blue---deep": "#1D2023",
  "blue---dark": "#202D3D",
  "blue---midnight": "#003057",
  "blue---link": "#007CFF",
  "blue---light": "#264F70",
  "blue---lightest": "#E1F3FE",
  "green---base": "#137C01",
  "green---light": "#26CD58",
  "green---lightest": "#E8FAEB",
  "orange---darkest": "#BA360A",
  "orange---dark": "#E04A17",
  "orange---base": "#F95721",
  "orange---light": "#FFA080",
  "orange---lightest": "#FFFCE4",
  "shades---black": "#000000",
  "shades---white": "#FFFFFF",
  "shades---transparent": "transparent",
  "blackberry---darkest": "#002094",
  "blackberry---dark": "#003DB7",
  "blackberry---base": "#014FCE",
  "blackberry---light": "#6384E0",
  "blackberry---lightest": "#E7EAFA",
  "blueberry---darkest": "#0048AA",
  "blueberry---dark": "#007CFF",
  "blueberry---base": "#0097FD",
  "blueberry---light": "#45B6FC",
  "blueberry---lightest": "#E1F3FE",
  "mint---darkest": "#00724D",
  "mint---dark": "#03A17B",
  "mint---base": "#00C19B",
  "mint---light": "#00D9BC",
  "mint---lightest": "#DFF9F5",
  "apple---darkest": "#027722",
  "apple---dark": "#04AA42",
  "apple---base": "#26CD58",
  "apple---light": "#74DF8B",
  "apple---lightest": "#E8FAEB",
  "lime---darkest": "#808201",
  "lime---dark": "#A6C100",
  "lime---base": "#C1EB1D",
  "lime---light": "#D3F36B",
  "lime---lightest": "#F8FEE7",
  "banana---darkest": "#F37F19",
  "banana---dark": "#FAC031",
  "banana---base": "#FBE739",
  "banana---light": "#FDF177",
  "banana---lightest": "#FFFDE8",
  "raspberry---darkest": "#8E003F",
  "raspberry---dark": "#C51345",
  "raspberry---base": "#EA1F49",
  "raspberry---light": "#F55F7E",
  "raspberry---lightest": "#FFE4E9",
  "cranberry---darkest": "#7F3363",
  "cranberry---dark": "#BB4079",
  "cranberry---base": "#E54887",
  "cranberry---light": "#E677AD",
  "cranberry---lightest": "#F9E7F0",
  "plum---darkest": "#471C61",
  "plum---dark": "#6D2D79",
  "plum---base": "#883888",
  "plum---light": "#A86EA7",
  "plum---lightest": "#F0E7F0",
  "text---headline": "#000000",
  "text---primary": "#1D2023",
  "text---secondary": "#626C77",
  "text---tertiary": "#969FA8",
  "text---inverted": "#FFFFFF",
  "text---positive": "#12B23F",
  "text---negative": "#EB4A13",
  "text---primary-link": "#007CFF",
  "text---primary-link-inverted": "#45B6FC",
  "text---secondary-link": "#969FA8",
  "background---primary": "#FFFFFF",
  "background---primary-elevated": "#FFFFFF",
  "background---modal": "#FFFFFF",
  "background---stroke": "rgba(188, 195, 208, 0.5)",
  "background---secondary": "#F2F3F7",
  "background---secondary-elevated": "#FFFFFF",
  "background---inverted": "#1D2023",
  "background---overlay": "rgba(29, 32, 35, 0.4)",
  "background---hover": "rgba(188, 195, 208, 0.25)",
  "icons---primary": "#000000",
  "icons---secondary": "#969FA8",
  "icons---tertiary": "#BBC1C7",
  "controls---primary-active": "#FF0032",
  "controls---secondary-active": "#1D2023",
  "controls---tertiary-active": "#F2F3F7",
  "controls---inactive": "rgba(188, 195, 208, 0.5)",
  "controls---alternative": "#FFFFFF",
  "controls---active-tabbar": "#FF0032",
  "controls---inactive-tabbar": "#AEB5BD",
  "accent---active": "#007CFF",
  "accent---positive": "#26CD58",
  "accent---warning": "#FAC031",
  "accent---negative": "#F95721",
  "accent---active-inverted": "#45B6FC",
  "accent---positive-inverted": "#74DF8B",
  "accent---warning-inverted": "#FAD67D",
  "accent---negative-inverted": "#FA8A64",
  "brand---base": "#FF0032",
  "grey---base": "#BBC1C7",
  "grey---light": "#E2E5EB",
  "grey---raven": "#6E7782",
  "grey---outline": "#DDE1E7",
  "grey---swift": "#626C77",
  "grey---dark": "#202D3D",
  "grey---soft": "#F2F3F7",
  "grey---s900": "#000000",
  "grey---s800": "#1D2023",
  "grey---s700": "#2C3135",
  "grey---s600": "#434A51",
  "grey---s500": "#626C77",
  "grey---s400": "#969FA8",
  "grey---s300": "#BBC1C7",
  "grey---s200": "#E2E5EB",
  "grey---s100": "#F2F3F7",
  "grey---s0": "#FFFFFF"
}