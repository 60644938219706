import { hyphenate } from '../Common'
import { isString, isUndefined, isNull } from "../Types"

const hyphenateObjectKeys = (target = {}) => (
  Object.entries(target).reduce((acc, [ key, value ]) => {
    acc[ hyphenate(key) ] = value
    return acc
  }, {})
)

/**
 * Выбирает из обьекта только те ключи которые подходят под регулярное выражение.
 * 
 * @default regex `^${ prefix }-(.*)`
 * 
 * @param {String} prefix Префикс для выборки атрибутов
 * @param {RegExpString} regExp Строка для кастомного регулярного выражения
 * @return {Function} Функция принимающая обьект атрибутов для выборки
 */
export const pick = (prefix, regExp) => {

  if (!prefix) return _ => _

  const regex = new RegExp(regExp ?? `^${ prefix }-(.*)`)

  return target => {

    const picked = Object
      .entries(hyphenateObjectKeys(target ?? {}))
      .reduce((acc, [ attrKey, attrValue ]) => {

        const [ input, target ] = attrKey.match(regex) || []

        /**
         * Добавим конвертацию значений.
         */
        if (target) {


          if (isUndefined(attrValue) || isNull(attrValue)) return acc

          let _value = attrValue

          if (isString(_value)) {

            if (_value === "") _value = true
            else if (_value === "true") _value = true
            else if (_value === "false") _value = false
            else {
              const _num = Number(_value)
              if (!isNaN(_num)) _value = _num
            }
          }
          
          acc[target] = _value
        }

        return acc
      }, {})

    return picked
  }
}

/**
 * Выбирает атрибуты из компонента.
 * 
 * @param {String} prefix Префикс для выборки атрибутов
 * @param {RegExpString} regExp Строка для кастомного регулярного выражения
 * @return {Function} Функция принимающая обьект атрибутов для выборки
 */
export const pickAttrs = (prefix, regExp) => {
  const picking = pick(prefix, regExp)

  return function () {
    return picking(this.$attrs)
  }
}

/**
 * Выбирает пропсы из компонента.
 * 
 * @param {String} prefix Префикс для выборки атрибутов
 * @param {RegExpString} regExp Строка для кастомного регулярного выражения
 * @return {Function} Функция принимающая обьект атрибутов для выборки
 */
export const pickProps = (prefix, regExp) => {
  const picking = pick(prefix, regExp)

  return function () {
    return picking(this.$props)
  }
}

/**
 * Выбирает атрибуты и пропсы из компонента.
 * 
 * `!!! Родительская функция не должна быть стрелочной !!!`
 * 
 * @param {String} prefix Префикс для выборки атрибутов
 * @param {RegExpString} regExp Строка для кастомного регулярного выражения
 * @return {Function} Функция принимающая обьект атрибутов для выборки
 */
export const pickAll = (prefix, regExp) => {
  const getAttrs = pickAttrs(prefix, regExp)
  const getProps = pickProps(prefix, regExp)
  return function () {
    return {
      ...getAttrs.call(this),
      ...getProps.call(this)
    }
  }
}