export default {
"$grid-column-items---mobile": "1",
"$grid-column-items---xss": "1",
"$grid-column-items---xs": "2",
"$grid-column-items---sm": "2",
"$grid-column-items---md": "3",
"$grid-column-items---lg": "3",
"$grid-column-items---xl": "4",
"$grid-column-items---xxl": "4",
"$grid-breakpoints---mobile": "0",
"$grid-breakpoints---xss": "415px",
"$grid-breakpoints---xs": "768px",
"$grid-breakpoints---sm": "960px",
"$grid-breakpoints---md": "1280px",
"$grid-breakpoints---lg": "1440px",
"$grid-breakpoints---xl": "1680px",
"$grid-breakpoints---xxl": "1920px",
"$grid-gutters---mobile": "4px",
"$grid-gutters---xss": "4px",
"$grid-gutters---xs": "8px",
"$grid-gutters---sm": "12px",
"$grid-gutters---md": "16px",
"$grid-gutters---lg": "24px",
"$grid-gutters---xl": "32px",
"$grid-gutters---xxl": "32px",
"$container-paddings-x---mobile": "20px",
"$container-paddings-x---xss": "20px",
"$container-paddings-x---xs": "40px",
"$container-paddings-x---sm": "40px",
"$container-paddings-x---md": "56px",
"$container-paddings-x---lg": "88px",
"$container-paddings-x---xl": "160px",
"$container-paddings-x---xxl": "184px",
"$display-breakpoints---print-only": "\"only print\"",
"$display-breakpoints---screen-only": "\"only screen\"",
"$display-breakpoints---xs-only": "\"only screen and (max-width: 959px)\"",
"$display-breakpoints---sm-only": "\"only screen and (min-width: 960px) and (max-width: 1279px)\"",
"$display-breakpoints---sm-and-down": "\"only screen and (max-width: 1279px)\"",
"$display-breakpoints---sm-and-up": "\"only screen and (min-width: 960px)\"",
"$display-breakpoints---md-only": "\"only screen and (min-width: 1280px) and (max-width: 1439px)\"",
"$display-breakpoints---md-and-down": "\"only screen and (max-width: 1439px)\"",
"$display-breakpoints---md-and-up": "\"only screen and (min-width: 1280px)\"",
"$display-breakpoints---lg-only": "\"only screen and (min-width: 1440px) and (max-width: 1679px)\"",
"$display-breakpoints---lg-and-down": "\"only screen and (max-width: 1679px)\"",
"$display-breakpoints---lg-and-up": "\"only screen and (min-width: 1440px)\"",
"$display-breakpoints---xl-only": "\"only screen and (min-width: 1680px)\"",
"$transition---fast-out-slow-in": "cubic-bezier(0.4, 0, 0.2, 1)",
"$transition---linear-out-slow-in": "cubic-bezier(0, 0, 0.2, 1)",
"$transition---fast-out-linear-in": "cubic-bezier(0.4, 0, 1, 1)",
"$transition---ease-in-out": "cubic-bezier(0.4, 0, 0.6, 1)",
"$transition---fast-in-fast-out": "cubic-bezier(0.25, 0.8, 0.25, 1)",
"$transition---swing": "cubic-bezier(0.25, 0.8, 0.5, 1)",
"$container-paddings-x-minis---mobile": "12px",
"$container-paddings-x-minis---xss": "12px",
"$container-paddings-x-minis---xs": "32px",
"$container-paddings-x-minis---sm": "32px",
"$container-paddings-x-minis---md": "32px",
"$container-paddings-x-minis---lg": "40px",
"$container-paddings-x-minis---xl": "40px",
"$container-paddings-x-minis---xxl": "40px",
"$negative-spacers---n0": "0",
"$negative-spacers---n1": "-2px",
"$negative-spacers---n2": "-4px",
"$negative-spacers---n3": "-6px",
"$negative-spacers---n4": "-8px",
"$negative-spacers---n5": "-10px",
"$negative-spacers---n6": "-12px",
"$negative-spacers---n7": "-14px",
"$negative-spacers---n8": "-16px",
"$negative-spacers---n9": "-20px",
"$negative-spacers---n10": "-24px",
"$negative-spacers---n11": "-32px",
"$negative-spacers---n12": "-40px",
"$negative-spacers---n13": "-48px",
"$negative-spacers---n14": "-64px",
"$negative-spacers---n15": "-80px",
"$spacers---0": "0",
"$spacers---1": "2px",
"$spacers---2": "4px",
"$spacers---3": "6px",
"$spacers---4": "8px",
"$spacers---5": "10px",
"$spacers---6": "12px",
"$spacers---7": "14px",
"$spacers---8": "16px",
"$spacers---9": "20px",
"$spacers---10": "24px",
"$spacers---11": "32px",
"$spacers---12": "40px",
"$spacers---13": "48px",
"$spacers---14": "64px",
"$spacers---15": "80px",
"ungrouped---$spacer": "4px",
"ungrouped---$scroll-bar-width": "16px",
"ungrouped---$mobile-breakpoint": "768px",
"ungrouped---$grid-gutter": "32px",
"ungrouped---$form-grid-gutter": "8px",
"ungrouped---$grid-columns": "12",
"ungrouped---$primary-transition-duration": "0.25s",
"ungrouped---$primary-transition": "0.25s cubic-bezier(0.25, 0.8, 0.5, 1)",
"ungrouped---$secondary-transition": "0.15s cubic-bezier(0.4, 0, 0.6, 1)"
}