/*
 Константа с дефолтным значением 1000
 */
export const msSecond = 1000

/*
 Константа с дефолтным значением msSecond * 1000
 */
export const msMinute = msSecond * 60

/*
 Константа с дефолтным значением msMinute * 60
 */
export const msHour = msMinute * 60

/*
 Константа с дефолтным значением msHour * 24
 */
export const msDay = msHour * 24

/*
  Константа с массивом имен месяцев (ru), пример - Январь
  { full: 'Январь',   short: 'Янв' },
  { full: 'Февраль',  short: 'Фев' },
  { full: 'Март',     short: 'Мар' },
  { full: 'Апрель',   short: 'Апр' },
  { full: 'Май',      short: 'Май' },
  { full: 'Июнь',     short: 'Июн' },
  { full: 'Июль',     short: 'Июл' },
  { full: 'Август',   short: 'Авг' },
  { full: 'Сентябрь', short: 'Сен' },
  { full: 'Октябрь',  short: 'Окт' },
  { full: 'Ноябрь',   short: 'Ноя' },
  { full: 'Декабрь',  short: 'Дек' }
 */
export const namedMounth = [
  { full: 'Январь',   short: 'Янв' },
  { full: 'Февраль',  short: 'Фев' },
  { full: 'Март',     short: 'Мар' },
  { full: 'Апрель',   short: 'Апр' },
  { full: 'Май',      short: 'Май' },
  { full: 'Июнь',     short: 'Июн' },
  { full: 'Июль',     short: 'Июл' },
  { full: 'Август',   short: 'Авг' },
  { full: 'Сентябрь', short: 'Сен' },
  { full: 'Октябрь',  short: 'Окт' },
  { full: 'Ноябрь',   short: 'Ноя' },
  { full: 'Декабрь',  short: 'Дек' }
]

/*
  Константа с массивом имен месяцев (ru), клон namedMounth
  { full: 'Января',   short: 'Янв' },
  { full: 'Февраля',  short: 'Фев' },
  { full: 'Марта',    short: 'Мар' },
  { full: 'Апреля',   short: 'Апр' },
  { full: 'Мая',      short: 'Май' },
  { full: 'Июня',     short: 'Июн' },
  { full: 'Июля',     short: 'Июл' },
  { full: 'Августа',  short: 'Авг' },
  { full: 'Сентября', short: 'Сен' },
  { full: 'Октября',  short: 'Окт' },
  { full: 'Ноября',   short: 'Ноя' },
  { full: 'Декабря',  short: 'Дек' }
 */
export const pluralNamedMounth = [
  { full: 'Января',   short: 'Янв' },
  { full: 'Февраля',  short: 'Фев' },
  { full: 'Марта',    short: 'Мар' },
  { full: 'Апреля',   short: 'Апр' },
  { full: 'Мая',      short: 'Мая' },
  { full: 'Июня',     short: 'Июн' },
  { full: 'Июля',     short: 'Июл' },
  { full: 'Августа',  short: 'Авг' },
  { full: 'Сентября', short: 'Сен' },
  { full: 'Октября',  short: 'Окт' },
  { full: 'Ноября',   short: 'Ноя' },
  { full: 'Декабря',  short: 'Дек' }
]

/*
 Константа с массивом дней недели(ru).
 Каждый день недели имеет поля:
 {
  plural: Воскресенье,
  full: Воскресенье,
  middle: Вск,
  small: Вс
 }
 */
export const namedDay = [
  {
    plural: "Воскресенье",
    full: "Воскресенье",
    middle: "Вск",
    small: "Вс"
  },
  {
    plural: "Понедельник",
    full: "Понедельник",
    middle: "Пнд",
    small: "Пн"
  },
  {
    plural: "Вторник",
    full: "Вторник",
    middle: "Втр",
    small: "Вт"
  },
  {
    plural: "Среду",
    full: "Среда",
    middle: "Срд",
    small: "Ср"
  },
  {
    plural: "Четверг",
    full: "Четверг",
    middle: "Чтв",
    small: "Чт"
  },
  {
    plural: "Пятницу",
    full: "Пятница",
    middle: "Птн",
    small: "Пт"
  },
  {
    plural: "Субботу",
    full: "Суббота",
    middle: "Сбт",
    small: "Сб"
  }
]