import { _toDate } from "./index"
import { msSecond, msMinute, msDay, msHour } from "./constants"

/**
 * База для прибавления
 *
 * @param {number} what Что нужно
 * @param {Date|string} where Над чем произвести операцию
 * @param {number} how_much Кол-во
 * @returns {number} Кол-во миллисекунд
 */
const incrementer = (what, where, how_much = 1) => (
  _toDate(where).getTime() + what * how_much
)

/**
 * База для вычитания
 * 
 * @param {number} what Что нужно
 * @param {Date|string} where Над чем произвести операцию
 * @param {number} how_much Кол-во
 * @returns {number} Кол-во миллисекунд
 */
const decrementer = (what, where, how_much = 1) => (
  _toDate(where).getTime() - what * how_much
)

/*
 * День
 */

/**
 * Добавляет кол-во дней в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во дней, по-умолчанию 1 день
 * @returns {number} Кол-во миллисекунд
 */
export const addDayTime = function (date, count) {
  return incrementer(msDay, date, count)
}

/*
 * Добавляет кол-во дней в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во дней, по-умолчанию 1 день
 * @returns {Date} Обьект Date
 */
export const addDayDate = function (date, count) {
  return new Date(addDayTime(date, count))
}

/*
 * Вычитает кол-во дней в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во дней, по-умолчанию 1 день
 * @returns {number} Кол-во миллисекунд
 */
export const subtractDayTime = function (date, count) {
  return decrementer(msDay, date, count)
}

/*
 * Вычитает кол-во дней в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во дней, по-умолчанию 1 день
 * @returns {Date} Обьект Date
 */
export const subtractDayDate = function (date, count) {
  return new Date(subtractDayTime(date, count))
}

/*
 * Часы
 */

/*
 * Добавляет кол-во часов в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во часов, по-умолчанию 1 час
 * @returns {number} Кол-во миллисекунд
 */
 export const addHoursTime = function (date, count) {
  return incrementer(msHour, date, count)
}

/*
 * Добавляет кол-во часов в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во часов, по-умолчанию 1 час
 * @returns {Date} Обьект Date
 */
export const addHoursDate = function (date, count) {
  return new Date(addHoursTime(date, count))
}

/*
 * Вычитает кол-во часов в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во часов, по-умолчанию 1 час
 * @returns {number} Кол-во миллисекунд
 */
export const subtractHoursTime = function (date, count) {
  return decrementer(msHour, date, count)
}

/*
 * Вычитает кол-во часов в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во часов, по-умолчанию 1 час
 * @returns {Date} Обьект Date
 */
export const subtractHoursDate = function (date, count) {
  return new Date(subtractHoursTime(date, count))
}


/*
 * Минуты
 */

/*
 * Добавляет кол-во минут в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во минут, по-умолчанию 1 минута
 * @returns {number} Кол-во миллисекунд
 */
 export const addMinutesTime = function (date, count) {
  return incrementer(msMinute, date, count)
}

/*
 * Добавляет кол-во минут в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во минут, по-умолчанию 1 минута
 * @returns {Date} Обьект Date
 */
export const addMinutesDate = function (date, count) {
  return new Date(addMinutesTime(date, count))
}

/*
 * Вычитает кол-во минут в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во минут, по-умолчанию 1 минута
 * @returns {number} Кол-во миллисекунд
 */
export const subtractMinutesTime = function (date, count) {
  return decrementer(msMinute, date, count)
}

/*
 * Вычитает кол-во минут в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во минут, по-умолчанию 1 минута
 * @returns {Date} Обьект Date
 */
export const subtractMinutesDate = function (date, count) {
  return new Date(subtractMinutesTime(date, count))
}

/*
 * Секунды
 */

/*
 * Добавляет кол-во секунд в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во секунд, по-умолчанию 1 секунда
 * @returns {number} Кол-во миллисекунд
 */
 export const addSecondsTime = function (date, count) {
  return incrementer(msSecond, date, count)
}

/*
 * Добавляет кол-во секунд в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во секунд, по-умолчанию 1 секунда
 * @returns {Date} Обьект Date
 */
export const addSecondsDate = function (date, count) {
  return new Date(addSecondsTime(date, count))
}

/*
 * Вычитает кол-во секунд в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во секунд, по-умолчанию 1 секунда
 * @returns {number} Кол-во миллисекунд
 */
export const subtractSecondsTime = function (date, count) {
  return decrementer(msSecond, date, count)
}

/*
 * Вычитает кол-во секунд в переданной дате
 * @param {Date|string} date Дата для которой нужно произвести операцию
 * @param {number} count Кол-во секунд, по-умолчанию 1 секунда
 * @returns {Date} Обьект Date
 */
export const subtractSecondsDate = function (date, count) {
  return new Date(subtractSecondsTime(date, count))
}

/*
 * Преобразует обычного объекта в объект даты
 * @param {Object: { day, month, year }} date Дата для которой нужно произвести операцию
 * @returns {Date} Обьект Date
 */
export const dateParsedFromObject =(date, endDay)=> {
    if (!endDay) {
        return new Date(date.year, date.month - 1, date.day)
    } else {
        return new Date(date.year, date.month - 1, date.day, 23, 59)
    }
}

/*
 * Преобразует объекта даты в обычный объект
 * @param {Date} Обьект Date
 * @returns {Date} {Object: { day, month, year }} date Дата для которой нужно произвести операцию
 */
export const dateParsedToObject =(date)=> {
    return {
        day: new Date(date).getDate(),
        month: new Date(date).getMonth() + 1,
        year: new Date(date).getFullYear(),
    }
}